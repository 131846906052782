import React from 'react'
import './Tokenomic.css'
import tokengrapg from '../../assets/tokengraph.jpg'


const tokenomic = () => {
    return (
        <div className='tokenomic'>
        <div className="content">
            <h2>CrossFi God TOKENOMIC</h2>
            <img class="tetris_img" src={tokengrapg} alt=""></img>
        </div>
    </div>
    )
}

export default tokenomic
