import React from 'react'
import MainVideo from '../../assets/CrossFIGOD.gif'
import './ioanVideo.css'

const ioanVideo = () => {
    return (
        <div className='ioanVideo'>
            <div class="video-containerIo">
                <img src={MainVideo} alt="Описание GIF" id='videoIo' /> {}
            </div>
        </div>
    )
}

export default ioanVideo
