import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="container">
                <div className="col">
                    <p><a className="col_a" href='https://t.me/crossfigod'>About</a></p>
                    <p><a className="col_a" href='https://www.youtube.com/channel/UCPuKlirExIzXWFZNXR-hPZQ'>YouTube</a></p>
                    <p><a className="col_a" href='https://t.me/crossfigod'>Book</a></p>
                    <p>Fairlaunch coming soon...</p>
                    <p>GOD AI coming soon...</p>
                </div>
                <button className="btn btn-outline">Donate</button>
            </div>
            <div className="footer_socials">
                <a href='https://x.com/crossfichain' class="social-icons__link">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.244 2.25H21.552L14.325 10.51L22.827 21.75H16.17L10.956 14.933L4.99003 21.75H1.68003L9.41003 12.915L1.25403 2.25H8.08003L12.793 8.481L18.244 2.25ZM17.083 19.77H18.916L7.08403 4.126H5.11703L17.083 19.77Z" fill="currentColor"></path>
                    </svg>                    </a>
                <a href="https://m.facebook.com/crossfiofficial" title="Go to Facebook page" class="social-icons__link">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 12.0726C24 5.44354 18.629 0.0725708 12 0.0725708C5.37097 0.0725708 0 5.44354 0 12.0726C0 18.0619 4.38823 23.0264 10.125 23.9274V15.5414H7.07661V12.0726H10.125V9.4287C10.125 6.42144 11.9153 4.76031 14.6574 4.76031C15.9706 4.76031 17.3439 4.99451 17.3439 4.99451V7.94612H15.8303C14.34 7.94612 13.875 8.87128 13.875 9.82015V12.0726H17.2031L16.6708 15.5414H13.875V23.9274C19.6118 23.0264 24 18.0619 24 12.0726Z" fill="currentColor"></path>
                    </svg>
                </a>
                <a href="https://discord.gg/crossfi" title="Go to Discord page" class="social-icons__link" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.432 11.4C15.432 12.132 14.892 12.732 14.208 12.732C13.536 12.732 12.984 12.132 12.984 11.4C12.984 10.668 13.524 10.068 14.208 10.068C14.892 10.068 15.432 10.668 15.432 11.4ZM9.828 10.068C9.144 10.068 8.604 10.668 8.604 11.4C8.604 12.132 9.156 12.732 9.828 12.732C10.512 12.732 11.052 12.132 11.052 11.4C11.064 10.668 10.512 10.068 9.828 10.068ZM22.5 2.472V24C19.4768 21.3284 20.4437 22.2127 16.932 18.948L17.568 21.168H3.96C2.604 21.168 1.5 20.064 1.5 18.696V2.472C1.5 1.104 2.604 0 3.96 0H20.04C21.396 0 22.5 1.104 22.5 2.472ZM19.08 13.848C19.08 9.984 17.352 6.852 17.352 6.852C15.624 5.556 13.98 5.592 13.98 5.592L13.812 5.784C15.852 6.408 16.8 7.308 16.8 7.308C13.9495 5.7457 10.6011 5.74542 7.836 6.96C7.392 7.164 7.128 7.308 7.128 7.308C7.128 7.308 8.124 6.36 10.284 5.736L10.164 5.592C10.164 5.592 8.52 5.556 6.792 6.852C6.792 6.852 5.064 9.984 5.064 13.848C5.064 13.848 6.072 15.588 8.724 15.672C8.724 15.672 9.168 15.132 9.528 14.676C8.004 14.22 7.428 13.26 7.428 13.26C7.60453 13.3836 7.89563 13.5437 7.92 13.56C9.94547 14.6943 12.8226 15.0659 15.408 13.98C15.828 13.824 16.296 13.596 16.788 13.272C16.788 13.272 16.188 14.256 14.616 14.7C14.976 15.156 15.408 15.672 15.408 15.672C18.06 15.588 19.08 13.848 19.08 13.848Z" fill="currentColor"></path>
                </svg>
                </a>
                <a href="https://medium.com/@crossfichain" title="Go to Medium-m page" class="social-icons__link" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                    <path d="M6.76875 0.900848C3.03049 0.900848 0 4.0794 0 7.99999C0 11.9206 3.03071 15.0991 6.76875 15.0991C10.5068 15.0991 13.5375 11.9208 13.5375 7.99999C13.5375 4.07917 10.5072 0.900848 6.76875 0.900848ZM17.5781 1.31671C15.7089 1.31671 14.1939 4.30964 14.1939 8.00011C14.1939 11.6906 15.7092 14.6837 17.5784 14.6837C19.4475 14.6837 20.9628 11.6908 20.9628 8.00011H20.9625C20.9625 4.30858 19.4475 1.31671 17.5782 1.31671H17.5781ZM22.8096 2.01284C22.1524 2.01284 21.6195 4.69362 21.6195 8.00011C21.6195 11.3066 22.152 13.9874 22.8096 13.9874C23.4672 13.9874 24 11.3059 24 7.99999C24 4.69339 23.4669 2.01284 22.8097 2.01284H22.8096Z" fill="currentColor"></path>
                </svg>
                </a>
                <a href="https://t.me/crossfichain" title="Go to Telegram page" class="social-icons__link" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM17.8935 8.22097L15.9242 17.5016C15.779 18.1597 15.3871 18.3194 14.8403 18.0097L11.8403 15.7984L10.3935 17.1919C10.2339 17.3516 10.0984 17.4871 9.78871 17.4871L10.0016 14.4339L15.5613 9.41129C15.8032 9.19839 15.5081 9.07742 15.1887 9.29032L8.31774 13.6161L5.35645 12.6919C4.7129 12.4887 4.69839 12.0484 5.49194 11.7387L17.0613 7.27742C17.5984 7.08387 18.0677 7.40806 17.8935 8.22097Z" fill="currentColor"></path>
                </svg>
                </a>
                <a href="https://www.linkedin.com/company/crossfichain/about/" title="Go to Linkedin-in page" class="social-icons__link" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.37214 23.9997H0.396429V7.97649H5.37214V23.9997ZM2.88161 5.79078C1.29054 5.79078 0 4.47292 0 2.88185C1.13882e-08 2.1176 0.303597 1.38465 0.844003 0.844247C1.38441 0.303841 2.11736 0.000244141 2.88161 0.000244141C3.64586 0.000244141 4.3788 0.303841 4.91921 0.844247C5.45962 1.38465 5.76321 2.1176 5.76321 2.88185C5.76321 4.47292 4.47214 5.79078 2.88161 5.79078ZM23.9946 23.9997H19.0296V16.1997C19.0296 14.3408 18.9921 11.9569 16.4427 11.9569C13.8557 11.9569 13.4593 13.9765 13.4593 16.0658V23.9997H8.48893V7.97649H13.2611V10.1622H13.3307C13.995 8.90328 15.6177 7.57471 18.0386 7.57471C23.0743 7.57471 24 10.8908 24 15.1979V23.9997H23.9946Z" fill="currentColor"></path>
                </svg>
                </a>
                <a href="https://www.youtube.com/channel/UCjRmS5kRmkH-qBTrAR73uLA" title="Go to Youtube page" class="social-icons__link" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4985 6.20286C23.2225 5.16356 22.4092 4.34503 21.3766 4.06726C19.505 3.5625 12 3.5625 12 3.5625C12 3.5625 4.49503 3.5625 2.62336 4.06726C1.59077 4.34508 0.777523 5.16356 0.501503 6.20286C0 8.08666 0 12.017 0 12.017C0 12.017 0 15.9474 0.501503 17.8312C0.777523 18.8705 1.59077 19.6549 2.62336 19.9327C4.49503 20.4375 12 20.4375 12 20.4375C12 20.4375 19.505 20.4375 21.3766 19.9327C22.4092 19.6549 23.2225 18.8705 23.4985 17.8312C24 15.9474 24 12.017 24 12.017C24 12.017 24 8.08666 23.4985 6.20286ZM9.54544 15.5855V8.44855L15.8181 12.0171L9.54544 15.5855Z" fill="currentColor"></path>
                </svg>
                </a>
                <a href="https://www.tiktok.com/@cross.finance?_t=8hZemMgjyZm&amp;_r=1" title="Go to Tiktok page" class="social-icons__link" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 9.84202C20.4357 9.84696 18.4221 9.20321 16.7435 8.00171V16.3813C16.7429 17.9333 16.2685 19.4482 15.3838 20.7233C14.499 21.9984 13.246 22.973 11.7923 23.5168C10.3387 24.0606 8.75362 24.1477 7.24914 23.7664C5.74466 23.3851 4.39245 22.5536 3.37333 21.383C2.3542 20.2125 1.71674 18.7587 1.54617 17.2161C1.3756 15.6735 1.68007 14.1156 2.41884 12.7507C3.15762 11.3858 4.2955 10.279 5.68034 9.57823C7.06517 8.87746 8.63095 8.61616 10.1683 8.82927V13.0439C9.46481 12.8227 8.70938 12.8293 8.0099 13.063C7.31041 13.2966 6.70265 13.7453 6.2734 14.345C5.84415 14.9446 5.61536 15.6646 5.6197 16.402C5.62404 17.1395 5.8613 17.8567 6.29759 18.4512C6.73387 19.0458 7.34688 19.4873 8.04906 19.7127C8.75125 19.9381 9.5067 19.9359 10.2075 19.7063C10.9084 19.4768 11.5188 19.0316 11.9515 18.4345C12.3843 17.8374 12.6173 17.1188 12.6173 16.3813V0H16.7435C16.7406 0.348435 16.7698 0.696395 16.8307 1.03948C16.9741 1.80537 17.2722 2.53396 17.7068 3.18068C18.1415 3.8274 18.7035 4.37867 19.3585 4.80075C20.2903 5.41688 21.3829 5.74528 22.5 5.74505V9.84202Z" fill="currentColor"></path>
                </svg>
                </a>
            </div>
            <div className="footer_right">
                <div className="footer_right">
                    © CROSSFIGOD 2024
                </div>
                <div className="footer_socials">
                    All rights reserved
                </div>
            </div>

        </div>
    );
};

export default Footer;
